// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { type StyleProps, parseComponentProp, parseStyleProps, } from '@haaretz/htz-css-tools';
import setColor from '../../utils/setColor';
import type { ColorPropType, } from '../../flowTypes/ColorPropType';


type PropsType = {
    children: React.ChildrenArray<React.Node> | React.Node,
    highlightColor: ColorPropType,
    isBlock?: boolean,
    miscStyles?: ?StyleProps,
    displayBefore?: boolean,
};

export default function Highlight({
  children,
  isBlock,
  highlightColor,
  miscStyles,
  displayBefore, }: PropsType) {
  const { css, } = useFela({ highlightColor, isBlock, miscStyles, displayBefore, });
  const wrapperClassName = css(wrapperStyle);
  const contentClassName = css(contentStyle);

  return (
    <div className={wrapperClassName}>
      <span className={contentClassName}>
        {children}
      </span>
    </div>
  );
}

function wrapperStyle({ theme, isBlock, highlightColor, miscStyles, displayBefore, }) {
  return {
    display: isBlock ? 'flex' : 'inline-flex',
    overflow: 'hidden',
    extend: [
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
    ...displayBefore ? { ':before': {
      content: '"\\00a0"',
      extend: [
        parseComponentProp(
          'backgroundColor',
          highlightColor,
          theme.mq,
          setColor,
          theme.color
        ),
      ],
    }, } : null,
  };
}

function contentStyle({ theme, highlightColor, }) {
  return {
    borderStyle: 'solid',
    borderWidth: '.25em 0',
    // whiteSpace: 'break-spaces', //TODO: Chrome bug - sometimes breaks the BG color.
    paddingInlineEnd: '.25em',
    extend: [
      parseComponentProp(
        'backgroundColor',
        highlightColor,
        theme.mq,
        setColor,
        theme.color
      ),
      parseComponentProp(
        'borderColor',
        highlightColor,
        theme.mq,
        setColor,
        theme.color
      ),
    ],
  };
}

Highlight.defaultProps = {
  isBlock: false,
  miscStyles: null,
  displayBefore: true,
};
