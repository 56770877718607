// @flow
import { borderBottom, } from '@haaretz/htz-css-tools';
import React from 'react';
import { useFela, } from 'react-fela';

import type { Node, } from 'react';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { TheTradeTypeType, } from '../../../../flowTypes/TheTradeTypeType';

import type { attrFlowType, } from '../../../../flowTypes/attrTypes';
import getImageAssets from '../../../../utils/getImageAssets';
import setBiAction from '../../../../utils/setBiAction';
import AdSlot from '../../../AdManager/AdSlot';
import H from '../../../AutoLevels/H';
import Section from '../../../AutoLevels/Section';
import Image from '../../../Image/Image';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import RainbowListPaywallSlot from '../../../Marketing/RainbowListPaywallSlot';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import Raphael from '../Raphael/Raphael';
import { container, defaultTeaser, mainTeaser, } from './gridStyles';

// const TheTrade = dynamic(import('../../../TheTrade/TheTrade'), {
//   loading: () => null,
// });

type Props = {
  list: ListDataType,
  isLazyloadImages: boolean,
  biAction: ?ListBiActionType,
  gaAction: ?() => void,
};

HawkingList.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

const teasers = [ HawkingMainTeaser, HawkingTeaser, HawkingTeaser, HawkingTeaser, ];

export default function HawkingList({ list, biAction, gaAction, isLazyloadImages, }: Props): Node {
  const { items, banners, theTradeTeaser, rainbowTargetSlot, ...restOfList } = list;
  const { theme, } = useFela();
  const [ isPaywallBlocked, setIsPaywallBlocked, ] = React.useState(false);
  const onRainbowToolRendered = () => setIsPaywallBlocked(true);

  const adSlot = banners?.adSlots || [];
  const clickTrackers = banners?.clickTrackerBanners || [];

  const dfpItem = adSlot && adSlot[0];

  return (
    <ListView
      areasTemplate={container.areasTemplate}
      rowTemplate={container.rowTemplate}
      colTemplate={container.colTemplate}
      attrs={{
        'data-test': 'hawking',
      }}
    >
      <ListViewHeader isSticky {...restOfList} biAction={biAction} />
      { rainbowTargetSlot
        ? (
          <RainbowListPaywallSlot
            id={rainbowTargetSlot}
            gridArea="t1 / t1 / t4 / t4"
            onToolRendered={onRainbowToolRendered}
          />
        )
        : null}
      <Section isFragment>
        {items
          && teasers.map(
            (T, i) => items[i] && (
            <T
              key={items[i].contentId}
              item={items[i]}
              isLazyloadImages={isLazyloadImages}
              onClick={setBiAction(i, items[i], biAction)}
              theme={theme}
              gridArea={`t${i + 1}`}
              attrs={isPaywallBlocked ? { 'aria-hidden': true, inert: '', } : {}}
            />
            )
          )}

        {/* CLICK TRACKERS / TheTrade */}
        {theTradeTeaser ? (
          <TheTradeTeaser
            type={theTradeTeaser.type}
            theTradeSubTitle={theTradeTeaser.theTradeSubTitle}
          />
        ) : clickTrackers ? (
          <Raphael
            title={theme.clickTrackerI18n.promotedContentLabel}
            items={clickTrackers}
            biAction={biAction}
            gaAction={gaAction}
            gridArea="ad"
          />
        ) : null}
      </Section>

      {/* BANNER */}
      {dfpItem && (
        <AdSlot
          loadPriority={list.loadPriority}
          {...dfpItem}
          wrapperMiscStyles={{
            display: [ { until: 's', value: 'none', }, { from: 'xl', value: 'none', }, ],
            gridArea: 'ad2',
          }}
        />
      )}
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                              TEASERS                               //
// /////////////////////////////////////////////////////////////////////

type TeaserProps = {
  item: TeaserDataType,
  isLazyloadImages?: boolean,
  onClick: ?() => void,
  theme: Object,
  gridArea: string,
  attrs?: ?attrFlowType,
};

HawkingMainTeaser.defaultProps = {
  isLazyloadImages: true,
  attrs: null,
};

function HawkingMainTeaser({
  item,
  onClick,
  gridArea,
  theme,
  isLazyloadImages,
  attrs,
}: TeaserProps): Node {
  const { hawkingMainTeaser, } = theme.hawkingStyle;

  const image = item?.mobileImage || item?.image;

  return (
    <Teaser
      areasTemplate={mainTeaser.areasTemplate}
      gridArea={gridArea}
      colTemplate={mainTeaser.colTemplate}
      rowTemplate={mainTeaser.rowTemplate}
      colGap={hawkingMainTeaser.colGap}
      rowGap="1rem"
      gridGap={null}
      attrs={attrs}
    >
      <TeaserMedia data={item} onClick={onClick}>
        <Image
          lazyLoad={isLazyloadImages}
          image={image}
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'headline',
            sizes: [
              { from: 'xl', size: '375px', },
              { from: 'l', until: 'xl', size: '400px', },
              { from: 'm', until: 'l', size: '483px', },
              { from: 's', until: 'm', size: '368px', },
              { size: 'calc(100vw - 24px)', },
            ],
            widths: [ 296, 351, 400, 483, 560, 960, ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        {...item}
        typeScale={hawkingMainTeaser.headerTypeScale}
        wrapperMiscStyles={hawkingMainTeaser.headerWrapperMiscStyles}
        onClick={onClick}
      />
      <TeaserFooter
        data={item}
        typeScale={-3}
        showRank={hawkingMainTeaser.footer.showRank}
        showAuthor
        showLive={!!item.liveUpdates}
        showCommentsCount={hawkingMainTeaser.footer.showCommentsCount}
        miscStyles={hawkingMainTeaser.footer.miscStyles}
        showTime={hawkingMainTeaser.footer.showTime}
      />
    </Teaser>
  );
}

HawkingTeaser.defaultProps = {
  isLazyloadImages: true,
  attrs: null,
};

function HawkingTeaser({ item, onClick, gridArea, theme, isLazyloadImages, attrs, }: TeaserProps): Node {
  const { teaser, } = theme.hawkingStyle;
  return (
    <Teaser
      areasTemplate={defaultTeaser.areasTemplate}
      colTemplate={defaultTeaser.colTemplate}
      rowTemplate={defaultTeaser.rowTemplate}
      gridGap="1rem"
      gridArea={gridArea}
      attrs={attrs}
    >
      <TeaserHeader
        isGridItem
        {...item}
        typeScale={teaser.headerTypeScale}
        onClick={onClick}
        wrapperMiscStyles={teaser.headerWrapperMiscStyles}
      />
      <TeaserFooter
        data={item}
        typeScale={-3}
        showAuthor={teaser.footer.showAuthor}
        showCommentsCount={teaser.footer.showCommentsCount}
        showLive={!!item.liveUpdates}
        miscStyles={teaser.footer.miscStyles}
      />
    </Teaser>
  );
}

TheTradeTeaser.defaultProps = {
  attrs: null,
};

function TheTradeTeaser({
  theTradeSubTitle,
  type,
}: {
  theTradeSubTitle: string,
  type: TheTradeTypeType,
  attrs?: ?attrFlowType,
}) {
  const { css, theme, } = useFela();

  const getValueColor = value => {
    const positive = theme.color('positive');
    const negative = theme.color('negative');
    const neutral = theme.color('bodyText');
    if (value.includes('קנייה')) return positive;
    if (value.includes('מכירה')) return negative;
    if (value.charAt(0) === '+') return positive;
    if (value.charAt(0) === '-') return negative;
    return neutral;
  };
  return (
    <Section className={css({ gridArea: 'ad', })}>
      <H
        className={css({
          fontWeight: 700,
          extend: [ borderBottom({ width: 2, style: 'solid', lines: 1, }), theme.type(-1), ],
        })}
      >
        {theme.theTradeTeaserI18n.title}
      </H>
      {theTradeSubTitle && (
        <p
          className={css({
            marginTop: '1rem',
            fontWeight: 700,
            extend: [ theme.type(-2), ],
          })}
        >
          {theTradeSubTitle}
        </p>
      )}

      {/* <TheTrade
        type={type}
        count={5}
        miscStyles={{ marginTop: '3rem', }}
        styles={(value: any, idx: number): {} => ({
          paddingStart: '2rem',
          direction: 'ltr',
          textAlign: 'start',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontWeight: 700,
          color: getValueColor(value),
        })}
      /> */}
      <footer
        className={css({
          textAlign: 'end',
          marginTop: '2rem',
          extend: [ borderBottom({ width: 1, style: 'solid', lines: 2, }), ],
        })}
      >
        <img
          className={css({ maxWidth: '79px', })}
          src={theme.theTradeTeaserI18n.img.src}
          alt={theme.theTradeTeaserI18n.img.alt}
        />
      </footer>
    </Section>
  );
}
