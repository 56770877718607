// @flow
import type { TeaserDataType, } from '../flowTypes/TeaserDataType';

export function calcReadingTime(wordsCount: number) {
  return Math.round(1000 * wordsCount / 3);
}

export function calcReadingProgress(items: Array<TeaserDataType>, readItems: Array<string>) {
  const progress = items.reduce((accumulator, item) => {
    const currentValue = item && item.wordCount ? item.wordCount : 0;
    const wasRead = readItems.includes(item.representedContent || item.contentId);

    accumulator.max += currentValue;
    accumulator.value += (wasRead ? currentValue : 0);

    return accumulator;
  }, { max: 0, value: 0, });

  return progress;
}
