// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import AudioPlayerWithActions from '../../../AudioPlayerWithActions/AudioPlayerWithActions';
import {
  AudioProgressController,
  PlayButton,
  SkipButton,
  AudioElapsedTime,
  AudioDuration,
} from '../../../HtzAudioPlayer';

type Props = {
  fileUrl: string,
  contentName: string,
};

const playerControllerWrapperStyle = ({ theme, }) => ({
  backgroundColor: theme.color('audioPlayerLabel', 'listItemBg'),
  display: 'grid',
  gridRowGap: '1rem',
  gridTemplateColumns: '1fr auto 1fr',
  color: theme.color('audioPlayerLabel', 'listItemColor'),
  fontWeight: 'bold',
  ...theme.type(-2),
  ...theme.mq(
    { until: 's', },
    {
      gridTemplateAreas: `
"progress progress progress"
${theme.direction === 'ltr'
    ? '"elapsed controls  duration"'
    : '"duration controls elapsed"'}
`,
    }
  ),
  ...theme.mq(
    { from: 's', },
    {
      gridTemplateAreas: `
".        .        .        controls"
"progress progress progress controls"
${theme.direction === 'ltr'
    ? '"elapsed .         duration  controls"'
    : '"duration .        elapsed  controls"'}
`,
      gridTemplateRows: '1fr auto 1fr',
      gridTemplateColumns: 'auto 1fr auto auto',
      gridColumnGap: '7rem',
    }
  ),
});

const playButtonMiscStyles = {
  marginInlineStart: '5rem',
  marginInlineEnd: '5rem',
};

const pauseBarHeight = [ { until: 'm', value: '2.5rem', }, { from: 'm', value: '20px', }, ];
const pauseBarWidth = [ { until: 'm', value: '4px', }, { from: 'm', value: '6px', }, ];
const pauseBarGap = [ { until: 'm', value: '3px', }, { from: 'm', value: '4px', }, ];

function SchoonhovenLabelAudioSkin({
  fileUrl,
  contentName,
}: Props): React.Node {
  const { css, theme, } = useFela();
  const isLtr = theme.direction === 'ltr';

  return (
    <AudioPlayerWithActions
      isOmny
      loadAfterFirstClick
      fileUrl={fileUrl}
      contentName={contentName}
      miscStyles={{
        gridArea: 'podcast',
        direction: 'unset',
      }}
    >
      <div className={css(playerControllerWrapperStyle)}>
        <AudioProgressController
          railColor={[ 'audioPlayerLabel', 'progressBar', ]}
          progressColor={[ 'audioPlayerLabel', 'listItemRail', ]}
        />
        <div
          className={css({
            gridArea: 'controls',
            display: 'flex',
            alignSelf: 'center',
            direction: 'rtl',
          })}
        >
          <SkipButton skipBy={15} textAttrs={{ y: '58%', }} />
          <PlayButton
            color={[ 'audioPlayerLabel', 'listItemPlayBtn', ]}
            bgColor={[ 'audioPlayerLabel', 'listItemPlayBg', ]}
            diameter={[
              { until: 'm', value: '6rem', },
              { from: 'm', value: '47px', },
            ]}
            playIconSize={[
              { until: 'm', value: 2.8, },
              { from: 'm', until: 'xl', value: 3.5, },
              { from: 'xl', value: 3, },
            ]}
            pauseBarHeight={pauseBarHeight}
            pauseBarWidth={pauseBarWidth}
            pauseBarGap={pauseBarGap}
            miscStyles={playButtonMiscStyles}
          />
          <SkipButton skipBy={-15} textAttrs={{ y: '58%', }} />
        </div>
        <AudioElapsedTime
          miscStyles={isLtr ? {
            justifySelf: 'start',
          } : null}
        />
        <AudioDuration
          miscStyles={isLtr ? {
            justifySelf: 'end',
          } : null}
        />
      </div>
    </AudioPlayerWithActions>
  );
}

export default SchoonhovenLabelAudioSkin;
