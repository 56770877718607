// @flow
import * as React from 'react';
import { GAMAL_LIST_QUERY, } from '@haaretz/graphql';
import GamalView from './GamalView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ItemPlaceholder from '../../../ItemPlaceholder/ItemPlaceholder';
import ListWrapper from '../../ListWrapper';

type GamalWrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
}

export default function GamalWrapper(props: GamalWrapperProps) {
  return (
    <ListWrapper {...props} query={GAMAL_LIST_QUERY} Placeholder={ItemPlaceholder} view="Gamal">
      {dataProps => <GamalView {...dataProps} />}
    </ListWrapper>
  );
}
