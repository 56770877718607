// @flow
import { useFela, } from 'react-fela';
import * as React from 'react';

import type { ComponentPropResponsiveObject, } from '@haaretz/htz-css-tools';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListBiImpressionType, } from '../../../../flowTypes/ListBiImpressionType';

import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { CountdownType, } from '../../../../flowTypes/CountdownType';
import type { IframeType, } from '../../../../flowTypes/IframeType';

import Image from '../../../Image/Image';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserContent from '../../../TeaserContent/NewTeaserContent';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';

import Iframe from '../../../Iframe/Iframe';
import useGetMediaComponent from '../../../../hooks/useGetMediaComponent';
import { getMediaProps, } from '../Wong/WongView';
import TeaserSubtitle from '../../../TeaserSubtitle/TeaserSubtitle';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';

import HtzLink from '../../../HtzLink/HtzLink';
import useOneTime from '../../../../hooks/useOneTime';

type Props = {
  gutter: ?number,
  list: ListDataType,
  gaAction: () => void,
  biAction: ?ListBiActionType,
  biImpression: ?ListBiImpressionType,
  isLazyloadImages: boolean,
  countdownObj: ?CountdownType,
  width: ?(number | ComponentPropResponsiveObject<number>[]),
  iframes: ?(IframeType[]),
};

Roberto.defaultProps = {
  gutter: null,
  width: null,
  gaAction: null,
  isLazyloadImages: true,
  countdownObj: null,
  iframes: null,
};

export default function Roberto({
  list,
  iframes,
  width,
  gutter,
  gaAction,
  biImpression,
  biAction: rawBiAction,
  isLazyloadImages,
  countdownObj,
}: Props): React.Node {
  const { theme, css, } = useFela();
  const { teaser, } = theme.robertoStyle;
  const getMediaComponent = useGetMediaComponent(Image);
  const { items, contentName, ...restOfList } = list || {};
  const item = items[0];
  const media = item.media || null;
  const MediaComponent = getMediaComponent(media && media.kind);
  const mediaProps = getMediaProps(media, true, theme);

  useOneTime(item && !!biImpression && typeof biImpression === 'function', setBiImpression(0, item, biImpression));


  const biAction = setBiAction(0, item, rawBiAction);
  return (
    <Teaser
      colTemplate={[
        { from: 'l', value: '1fr', },
        { until: 'l', value: '0 1fr 0', },
      ]}
      areasTemplate={[
        {
          until: 's',
          value: `
        "...  content ..."
        "...  ....... ..."
        "... footer ..."
        "...  ....... ..."
        "...  ....... ..."
        "...  iframe  ..."
        `,
        },
        {
          from: 's',
          until: 'l',
          value: `
        "media media media"
        "...  content ..."
        "...  ....... ..."
        "... footer ..."
        "...  ....... ..."
        "...  ....... ..."
        "...  iframe  ..."
        `,
        },
        {
          from: 'l',
          value: `
        "content"
        "."
        "footer"
        "."
        "."
        "iframe"
        `,
        },
      ]}
      colGap={[
        { until: 's', value: '2rem', },
        { from: 'l', until: 'xl', value: '4rem', },
        { from: 'xl', value: '2rem', },
      ]}
      rowGap="1rem"
      gridGap={null}
      miscStyles={{
        margin: [
          { until: 's', value: '0 -2rem', },
          { from: 's', value: '0', },
        ],
        width: 'auto',
        gridColumnEnd: [ { from: 'l', value: 'span 2', }, ],
      }}
      attrs={{
        'data-test': 'roberto',
      }}
    >
      <TeaserMedia
        data={item}
        onClick={biAction}
        miscStyles={{
          display: [
            { until: 's', value: 'none', },
            { from: 'l', value: 'none', },
            teaser.showImageMediumBp
              ? {}
              : { from: 's', until: 'l', value: 'none', },
          ],
        }}
      >
        {mediaProps ? (
          <MediaComponent {...mediaProps} lazyLoad={isLazyloadImages} />
        ) : null}
      </TeaserMedia>
      <TeaserContent
        miscStyles={{
          position: 'relative',
          ...theme.mq(
            { until: 's', },
            { textAlign: 'center', marginTop: '3rem', }
          ),
        }}
      >
        {restOfList.title && !item.exclusiveMobile ? (
          <HtzLink
            href={restOfList.url || ''}
            onClick={biAction}
            className={css({
              extend: [
                theme.mq({ from: 's', }, { display: 'none', }),
                theme.mq(
                  { until: 's', },
                  {
                    color: theme.color(teaser.titleVariant),
                    ':visited': { color: theme.color(teaser.titleVariant), },
                    fontWeight: '700',
                    ...(teaser.hdcTitleFontFamily
                      ? { fontFamily: theme.fontStacks[theme.framedFont], }
                      : {}),
                  }
                ),
              ],
            })}
          >
            {restOfList.title}
          </HtzLink>
        ) : null}
        <TeaserHeader
          kickerIsBlock
          isH1
          {...item}
          typeScale={teaser.headerTypeScale}
          kickerTypeScale={teaser.kickerTypeScale}
          kickerMiscStyles={{
            position: 'relative',
            zIndex: 1,
            pointerEvents: 'none',
            marginInlineStart: [ { until: 's', value: '-2rem', }, ],
            marginInlineEnd: [ { until: 's', value: '-2rem', }, ],
            marginBlockEnd: [
              { until: 'l', value: '2rem', },
              { from: 'l', value: '2.4rem', },
            ],
          }}
          kickerInnerMiscStyles={{
            paddingInlineStart: [ { until: 's', value: '2rem', }, ],
            paddingInlineEnd: [ { until: 's', value: '2rem', }, ],
          }}
          onClick={biAction}
          {...(countdownObj
            ? {
              showKicker: false,
              countdownObj,
              countdownMiscStyles: {
                position: [ { until: 'l', value: 'absolute', }, ],
                top: '0',
                start: '0',
                zIndex: 1,
                pointerEvents: 'none',
                marginInlineStart: [ { until: 's', value: '-2rem', }, ],
                marginInlineEnd: [ { until: 's', value: '-2rem', }, ],
                marginTop: [ { until: 'l', value: '-1rem', }, ],
                transform: [ { until: 'l', value: 'translateY(-100%)', }, ],
              },
            }
            : {})}
          wrapperMiscStyles={{
            ...theme.mq({ until: 's', }, { marginTop: '1rem', }),
          }}
        />
        <TeaserSubtitle
          {...item}
          typeScale={teaser.subtitleTypeScale}
          miscStyles={{
            display: [ { until: 's', value: 'none', }, ],
            marginTop: [ { from: 's', value: '1rem', }, ],
            fontWeight: 400,
          }}
        />
      </TeaserContent>
      <TeaserFooter
        swapReportingFromAndTime
        showAuthor
        showLive={!!item.liveUpdates}
        data={item}
        typeScale={teaser.footerTypeScale}
        miscStyles={{
          marginBlockStart: [ { until: 's', value: '-1rem', }, ],
          textAlign: [ { until: 's', value: 'center', }, ],
        }}
      />
      <div
        className={css({
          gridArea: 'iframe',
        })}
        data-test="robertoIframe"
      >
        {iframes
          && iframes.map(iframe => <Iframe key={iframe.contentId} {...iframe} />)}
      </div>
    </Teaser>
  );
}
