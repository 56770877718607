export default function getBoxyGifInlineScript(id) {
  return `
  function handleBoxySrc(a){var e=document.querySelector(a);if(e){var t=JSON.parse(e.dataset.srcs||null);if(t){var c=matchMedia(e.dataset.mq);r(c),c.addEventListener("change",r)}}function r(a){a.matches?e.src=t[0]:e.src=t[1]}}
  handleBoxySrc('video#${id}');
  `;
}

// // ****** script mold ******* //
// function handleBoxySrc(id) {
//   const vidEl = document.querySelector(id);
//   if (!vidEl) return;

//   const srcs = JSON.parse(vidEl.dataset.srcs || null);
//   if (!srcs) return;

//   const mq = matchMedia(vidEl.dataset.mq);
//   updateSrc(mq);
//   mq.addEventListener('change', updateSrc);

//   function updateSrc(mq) {
//     if (mq.matches) {
//       vidEl.src = srcs[0];
//     }
//     else {
//       vidEl.src = srcs[1];
//     }
//   }
// }
