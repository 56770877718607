// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import type { GalleryDataType, } from '../../../../flowTypes/GalleryDataType';
import type { HTMLEmbedDataType, } from '../../../../flowTypes/HTMLEmbedDataType';
import type { ImageDataType, } from '../../../../flowTypes/ImageDataType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListBiImpressionType, } from '../../../../flowTypes/ListBiImpressionType';

import type { CountdownType, } from '../../../../flowTypes/CountdownType';

import { isImage, isEmbed, isGallery, } from '../../../../utils/validateType.js';
import Picture from '../../../Image/Picture';
import Image from '../../../Image/Image';
import RelatedArticleLinks from './RelatedArticleLinks';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserContent from '../../../TeaserContent/NewTeaserContent';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserSubtitle from '../../../TeaserSubtitle/TeaserSubtitle';
import useGetMediaComponent from '../../../../hooks/useGetMediaComponent';
import getPictureAssets from '../../../../utils/getPictureAssets';
import getImageAssets from '../../../../utils/getImageAssets';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';

import useGetComponent from '../../../../hooks/GetComponentContext/useGetComponent';
import useOneTime from '../../../../hooks/useOneTime';

type Props = {
  // Conrad and wong are almost identical with small differences in xl bps
  // Conrad actually renders Wong list with isConrad prop that effects the styles
  isConrad: boolean,
  isDark: boolean,
  list: ListDataType,
  biAction: ?ListBiActionType,
  biImpression: ?ListBiImpressionType,
  isLazyloadImages: boolean,
  countdownObj: ?CountdownType,
  numberOfRelatedArticles?: number,
};

const areasTeaser = `
"media content"
"media  footer"
"media   links"
`;
const areasTeaserReverse = `
"content media"
"footer  media"
"links   media"
`;
const areasStackedTeaser = `
"media media media"
"...   content ..."
"...   footer  ..."
"...   links   ..."
`;

const getStyles = (isConrad, isDark, theme) => ({
  areasTemplate: [
    { until: 'l', value: areasStackedTeaser, },
    { from: 'l', until: 'xl', value: areasTeaser, },
    { from: 'xl', value: isConrad ? areasTeaser : areasTeaserReverse, },
  ],
  rowTemplate: 'auto auto 1fr',
  colTemplate: [
    { until: 'l', value: '0 auto 0', },
    { from: 'l', until: 'xl', value: '7fr 5fr', },
    { from: 'xl', value: isConrad ? '50% 1fr' : '3fr 4fr', },
  ],
  colGap: [
    { until: 's', value: '2rem', },
    { from: 'l', until: 'xl', value: '4rem', },
    { from: 'xl', value: '2rem', },
  ],
  miscStyles: {
    margin: [ { until: 's', value: '0 -2rem', }, ],
    gridColumnEnd: [
      { from: 'l', until: isConrad ? undefined : 'xl', value: 'span 2', },
    ],
    width: 'auto',
    ...isDark ? {
      color: 'white',
      position: 'relative',
      marginBottom: [ { until: 's', value: '3.5rem', }, { from: 's', value: '4rem', }, ],
      zIndex: 0,
      ':after': {
        content: '""',
        backgroundColor: theme.color('neutral', '-1'),
        position: 'absolute',
        top: '-4rem',
        left: '-4rem',
        width: 'calc(100% + 8rem)',
        height: 'calc(100% + 8rem)',
        zIndex: -1,
      },
    } : {},
  },
  typeScale: theme.conradAndWongStyle.headerTypeScale(isConrad),
  kickerTypeScale: [ { until: 'l', value: 0, }, { from: 'l', value: -1, }, ],
  kickerMiscStyles: {
    position: [ { until: 'l', value: 'absolute', }, ],
    top: '0',
    start: '0',
    zIndex: 1,
    pointerEvents: 'none',
    marginBottom: '1rem',
    marginInlineStart: [ { until: 's', value: '-2rem', }, ],
    marginInlineEnd: [ { until: 's', value: '-2rem', }, ],
    marginTop: [ { until: 'l', value: '-1rem', }, ],
    transform: [ { until: 'l', value: 'translateY(-100%)', }, ],
  },
  kickerInnerMiscStyles: {
    paddingInlineStart: [ { until: 's', value: '2rem', }, ],
    paddingInlineEnd: [ { until: 's', value: '2rem', }, ],
  },
  subtitleTypeScale: [
    { from: 's', until: 'l', value: 0, },
    { until: 'xl', value: 0, },
    { from: 'xl', value: -1, },
  ],
  subtitleMiscStyles: {
    display: [ { until: 's', value: 'none', }, ],
    marginTop: [ { from: 's', value: '1rem', }, ],
    fontWeight: 400,
  },
  footerTypeScale: [
    { until: 's', value: -3, },
    { from: 's', until: 'xl', value: -2, },
    { from: 'xl', value: -3, },
  ],
});

Wong.defaultProps = {
  isConrad: false,
  isDark: false,
  isLazyloadImages: false,
  biAction: null,
  biImpression: null,
  countdownObj: null,
  numberOfRelatedArticles: 3,
};
export default function Wong({
  isConrad,
  isLazyloadImages,
  list,
  biAction: rawBiAction,
  biImpression,
  countdownObj,
  numberOfRelatedArticles,
  isDark,
}: Props): React.Node {
  const [ item, ] = list?.items || [];

  const relatedPadding = '2rem';
  const { theme, } = useFela();
  const getComponent = useGetComponent();
  const ImageGalleryTeaser = getComponent('imageGalleryTeaser');
  const getMediaComponent = useGetMediaComponent(isConrad ? Image : Picture, ImageGalleryTeaser);

  useOneTime(item && !!biImpression && typeof biImpression === 'function', setBiImpression(0, item, biImpression));

  if (!item) { return null; }

  const media = item.media || null;
  const MediaComponent = getMediaComponent(media && media.kind);
  const mediaProps = getMediaProps(media, isConrad, theme);
  const biAction = setBiAction(0, item, rawBiAction);

  const {
    areasTemplate,
    rowTemplate,
    colTemplate,
    colGap,
    miscStyles,
    typeScale,
    kickerTypeScale,
    kickerMiscStyles,
    kickerInnerMiscStyles,
    subtitleTypeScale,
    subtitleMiscStyles,
    footerTypeScale,
  } = getStyles(isConrad, isDark, theme);


  return (
    <Teaser
      areasTemplate={areasTemplate}
      colTemplate={colTemplate}
      rowTemplate={rowTemplate}
      colGap={colGap}
      rowGap="1rem"
      gridGap={null}
      miscStyles={miscStyles}
      attrs={{
        'data-test': isConrad ? 'conrad' : 'wong',
      }}
    >
      <TeaserMedia data={item} onClick={biAction}>
        {mediaProps ? <MediaComponent {...mediaProps} lazyLoad={isLazyloadImages} /> : null}
      </TeaserMedia>
      <TeaserContent miscStyles={{ position: 'relative', }}>
        <TeaserHeader
          kickerIsBlock
          isH1
          {...item}
          typeScale={typeScale}
          kickerTypeScale={kickerTypeScale}
          kickerMiscStyles={kickerMiscStyles}
          kickerInnerMiscStyles={kickerInnerMiscStyles}
          onClick={biAction}
          {...(countdownObj
            ? {
              showKicker: false,
              countdownObj,
              countdownMiscStyles: kickerMiscStyles,
            }
            : {})}
        />
        <TeaserSubtitle
          {...item}
          typeScale={subtitleTypeScale}
          miscStyles={subtitleMiscStyles}
        />
      </TeaserContent>
      <TeaserFooter
        swapReportingFromAndTime
        showAuthor
        showLive={!!item.liveUpdates}
        data={item}
        typeScale={footerTypeScale}
        {...isDark ? { commentsColor: [ 'link', 'darkMainBlockRelatedArticles', ], miscStyles: { color: 'white', }, } : {}}
      />
      {item.relatedArticles && (
        <RelatedArticleLinks
          relatedArticles={item.relatedArticles}
          biAction={rawBiAction}
          relatedPadding={relatedPadding}
          numberOfRelatedArticles={numberOfRelatedArticles}
          isDark={isDark}
        />
      )}
    </Teaser>
  );
}

// /////////////////////////////////////////////////////////////////////
//                               UTILS                                //
// /////////////////////////////////////////////////////////////////////

const conradImgOptions = theme => ({
  bps: theme.bps,
  aspect: 'headline',
  sizes: [
    { from: 'xl', size: '604px', },
    { from: 'l', size: '556px', },
    { from: 'm', size: '720px', },
    { from: 's', size: '552px', },
    { size: '100wv', },
  ],
  widths: [ 375, 469, 556, 604, 720, ],
});

const wongPictureOptions = (theme, imgData) => ({
  bps: theme.bps,
  imgData,
  defaultImgOptions: {
    sizes: [ { from: 'xl', size: '470px', }, { size: '100vw', }, ],
    aspect: 'regular',
    widths: [ 375, 470, 600, ],
  },
  sources: [
    {
      from: 's',
      until: 'xl',
      aspect: 'headline',
      sizes: [
        { from: 'l', size: '556px', },
        { from: 'm', size: '720px', },
        { size: '552px', },
      ],
      widths: [ 556, 772, 1000, ],
    },
  ],
});

function getImageProps(
  media: ImageDataType,
  isConrad: boolean,
  theme: Object
): Object {
  return isConrad
    ? {
      image: media,
      imgOptions: getImageAssets(conradImgOptions(theme)),
    }
    : getPictureAssets(wongPictureOptions(theme, media));
}

function getEmbedProps(media: HTMLEmbedDataType): Object {
  return media.inputTemplate === 'Youtube'
    ? {
      ...media,
      source: media.source,
      embedType: media.embedType,
      settings: {
        ...media.settings,
        controls: '0',
        autoplay: true,
        loop: '1',
        logo: '1',
        startAt: 0,
        related: '0',
        mute: true,
      },
      showCaption: false,
      inputTemplate: media.inputTemplate,
      caption: media.caption,
      credit: media.credit,
    }
    : {
      ...media,
      source: media.source,
      embedType: media.embedType,
      settings: media.settings,
      showCaption: false,
      inputTemplate: media.inputTemplate,
      caption: media.caption,
      credit: media.credit,
    };
}

export function getMediaProps(
  media: ?(ImageDataType | HTMLEmbedDataType | GalleryDataType),
  isConrad: boolean,
  theme: Object
): ?Object {
  if (media) {
    if (isImage(media)) return getImageProps(media, isConrad, theme);
    if (isEmbed(media)) return getEmbedProps(media);
    if (isGallery(media)) {
      return {
        ...media,
        showTitle: theme.direction !== 'ltr',
        isPicture: !isConrad,
        imgOptions: isConrad ? conradImgOptions : wongPictureOptions,
      };
    }
  }
  return null;
}
