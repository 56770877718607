// @flow
import * as React from 'react';
import { MOUSEPAD_LIST_QUERY, } from '@haaretz/graphql';
import MousepadView from './MousepadView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type MousepadWrapperProps = {
  updateListDuplication: Function,
  variables: {
    history: ?(string[]),
    getHistory: ?(() => string[]),
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}

export default function MousepadWrapper(props: MousepadWrapperProps) {
  return (
    <ListWrapper {...props} query={MOUSEPAD_LIST_QUERY} view="Mousepad">
      {dataProps => <MousepadView {...dataProps} />}
    </ListWrapper>
  );
}
