// @flow
type Accuracy = 'millis' | 'seconds' | 'minutes' | 'hours' | 'days';
type DisplayAccuracy = Accuracy | 'auto';

const accuracies = [ 'millis', 'seconds', 'minutes', 'hours', 'days', ];
const accuracySizes = [ 1000, 60, 60, 24, ];


/**
 * Converts machine time to human-readable time string.
 * ex':
 *  toTimeString(100000) => 1:40:00
 *  toTimeString(100000, 'seconds') => 1:40
 *  toTimeString(100000, 'minutes') => 0
 * @param {number} millis - time in milliseconds
 * @param {string} accuracy - the accuracy of the time string (rounds the time to nearst value)
 */
export function toTimeParts(millis: number, accuracy: Accuracy = 'millis', displayAccuracy: DisplayAccuracy = 'auto') {
  const accuracyRegx = new RegExp(`(auto)|(${accuracy})`);
  let accuracyIdx = accuracies.indexOf(accuracy);
  let dispalyAccuracyIdx = accuracyRegx.test(displayAccuracy)
    ? accuracyIdx
    : accuracies.indexOf(displayAccuracy);

  accuracyIdx = accuracyIdx > -1 ? accuracyIdx : 0;
  dispalyAccuracyIdx = dispalyAccuracyIdx > -1 ? dispalyAccuracyIdx : 0;

  const timeParts = [];
  let time = millis;
  let timeRepresentationIndex = 0;
  let timeRepresentationName = accuracies[timeRepresentationIndex];

  const accuracyFactor = accuracySizes
    .slice(0, accuracyIdx)
    .reduce((factor, value) => factor * value, 1);

  // Round the time to the nearst required accuracy.
  // Ex: 100000 millis are 1:40 minutes.
  //     So if accuracy is set to 'minutes' the function shuold return 2 minutes.
  time = Math.round(time / accuracyFactor);

  if (millis === 0) {
    timeRepresentationName = accuracies[accuracyIdx];
    timeRepresentationIndex = accuracyIdx;
    timeParts.unshift(0);
  }

  for (let i = accuracyIdx; i < accuracySizes.length && time > 0; i += 1) {
    const part = time % accuracySizes[i];
    timeParts.unshift(part);
    time = (time - part) / accuracySizes[i];
    timeRepresentationName = accuracies[i];
    timeRepresentationIndex = i;
  }

  if (dispalyAccuracyIdx > timeRepresentationIndex) {
    for (let i = timeRepresentationIndex + 1; i <= dispalyAccuracyIdx; i += 1) {
      timeParts.unshift(0);
      timeRepresentationName = accuracies[i];
    }
  }

  timeParts.unshift(timeRepresentationName);

  return timeParts;
}

export function toHumanTimeString(millis: number, accuracy: Accuracy = 'millis', displayAccuracy: DisplayAccuracy = 'auto', durationI18n?: ?Object) {
  let timeParts = toTimeParts(millis, accuracy, displayAccuracy);

  timeParts = timeParts.map((p, i) => {
    let newVal = p ? p.toString() : '0';
    if (i > 1) {
      newVal = newVal.padStart(2, '0');
    }
    return newVal;
  });

  const duration = durationI18n && durationI18n[timeParts[0]] != null
    ? durationI18n[timeParts[0]]
    : timeParts[0];

  return `${timeParts.slice(1).join(':')} ${duration}`;
}
