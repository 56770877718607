// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import Picture, { PictureWrapper, } from '../../../Image/Picture';
import { formatImageTitle, } from '../../../Image/utils';
import useGetMediaComponent from '../../../../hooks/useGetMediaComponent';
import { isImage, isEmbed, } from '../../../../utils/validateType';
import { buildURLs, } from '../../../../utils/buildImgURLs';
import getBoxyGifInlineScript from './getBoxyGifInlineScript';
import useIsAnimationReduced from '../../../../hooks/useIsAnimationReduced';

import type { PicturePropsType, } from '../../../../flowTypes/PicturePropsType';
import type { ImageDataType, } from '../../../../flowTypes/ImageDataType';
import type { HTMLEmbedDataType, } from '../../../../flowTypes/HTMLEmbedDataType';
import type { GalleryDataType, } from '../../../../flowTypes/GalleryDataType';


type MediaType = ImageDataType | HTMLEmbedDataType | GalleryDataType;

type Props = {
  media: MediaType,
  isLazyloadImages: boolean,
};

const animatedImageInnerStyles = {
  position: 'absolute',
  top: '0',
  start: '0',
  width: '100%',
  height: '100%',
  objectFit: 'fill',
};

const boxyAnimatedImageOptions = [
  { aspect: 'vertical', width: 600, },
  { aspect: 'landscape', width: 1200, },
].map(({ width, aspect, }) => ({
  aspect,
  width,
  format: 'mp4',
  quality: '60',
}));

function BoxyAnimatedImage({ image, defaultImg, sources, }: PicturePropsType): React.Node {
  const { css, theme, } = useFela();

  const isAnimationReduced = useIsAnimationReduced();
  const videoRef = React.useRef(null);
  React.useEffect(() => {
    const video = videoRef.current;
    if (video) {
      if (isAnimationReduced) {
        video.pause();
        video.currentTime = 0;
      }
      else if (video.paused) {
        video.play();
      }
    }
  }, [ isAnimationReduced, ]);

  if (!image) return null;

  const {
    contentId,
    title,
    credit,
    imgArray: [ imgData, ] = [],
  } = image;

  const innerClassName = css(animatedImageInnerStyles);

  const srcs = buildURLs(
    contentId,
    imgData,
    boxyAnimatedImageOptions,
    { excludeWidthDescriptor: true, }
  );

  const mobileMqStr = theme.getMqString({ until: 's', }, true);

  const id = `boxy-${contentId.replace('.', '-')}`;

  return (
    <PictureWrapper data={data} sources={sources} defaultImg={defaultImg}>
      <video
        ref={videoRef}
        id={id}
        data-srcs={JSON.stringify(srcs)}
        data-mq={mobileMqStr}
        title={formatImageTitle({ title, credit, theme, })}
        className={innerClassName}
        controls={false}
        playsInline
        loop
        autoPlay={!isAnimationReduced}
        muted
      />
      {/*
        The video needs to appear as fast as possible, and that is why an inline script
        is being used to set the src, rather than some React mechanism, as it will run
        significantly earlier than any React hook.
        Note that this will only work when the component is server-rendered.
        Should the component or any of its parent components unmount and later
        mount again, the next script will not run.
        If such a scenario is needed in the future, a more robust solution will
        have to be implemented.
      */}
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: getBoxyGifInlineScript(id),
        }}
      />
    </PictureWrapper>
  );
}

function BoxyImage(props: PicturePropsType): React.Node {
  return props.image && (props.image.inputTemplate === 'com.tm.AnimatedGif' || props.image.isAnimatedGif) ? (
    <BoxyAnimatedImage {...props} />
  ) : (
    <Picture {...props} />
  );
}

export default function BoxyMedia({
  media,
  isLazyloadImages,
}: Props): React.Node {
  const getMediaComponent = useGetMediaComponent(BoxyImage);
  const MediaComponent = getMediaComponent(media && media.kind);
  const mediaProps = media ? getMediaProps(media, isLazyloadImages) : null;
  return MediaComponent && mediaProps ? (
    <MediaComponent {...mediaProps} />
  ) : null;
}

// /////////////////////////////////////////////////////////////////////
//                               UTILS                                //
// /////////////////////////////////////////////////////////////////////

type SourceOptions = {
  sizes: string,
  transforms: Array<{ width: string, aspect: 'vertical' | 'landscape', }>,
};

function getSourceOptions(isMobile: boolean) {
  const aspect = isMobile ? 'vertical' : 'landscape';
  return {
    sizes: '(min-width:1280px) 1280px, 100vw',
    transforms: [
      { width: '375', aspect, },
      { width: '425', aspect, },
      { width: '600', aspect, },
      { width: '768', aspect, },
      { width: '1028', aspect, },
      { width: '1280', aspect, },
      { width: '1920', aspect, },
    ],
  };
}

type ImageProps = {
  image: ImageDataType,
  defaultImg: {
    sourceOptions: SourceOptions,
  },
  sources: [
    {
      until: 's',
      sourceOptions: SourceOptions,
    },
    {
      from: 's',
      sourceOptions: SourceOptions,
    },
  ],
};

function getImageProps(media: ImageDataType, lazyLoad: boolean): ImageProps {
  return {
    image: media,
    defaultImg: {
      sourceOptions: getSourceOptions(false),
    },
    sources: [
      {
        until: 's',
        sourceOptions: getSourceOptions(true),
      },
      {
        from: 's',
        sourceOptions: getSourceOptions(false),
      },
    ],
    lazyLoad,
  };
}

function getEmbedProps(media: HTMLEmbedDataType): Object {
  return media.inputTemplate === 'Youtube'
    ? {
      ...media,
      source: media.source,
      embedType: media.embedType,
      settings: {
        ...media.settings,
        controls: '0',
        autoplay: true,
        loop: '1',
        logo: '1',
        startAt: 0,
        related: '0',
        mute: true,
      },
      showCaption: false,
      inputTemplate: media.inputTemplate,
      caption: media.caption,
      credit: media.credit,
    }
    : {
      ...media,
      source: media.source,
      embedType: media.embedType,
      settings: media.settings,
      showCaption: false,
      inputTemplate: media.inputTemplate,
      caption: media.caption,
      credit: media.credit,
    };
}

export function getMediaProps(media: MediaType, lazyLoad: boolean): ?Object {
  if (isImage(media)) return getImageProps(media, lazyLoad);
  if (isEmbed(media)) return getEmbedProps(media);

  return null;
}
