// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';

const headerType = [
  { until: 's', value: 0, },
  { from: 's', until: 'l', value: -1, },
  { from: 'l', until: 'xl', value: 0, },
  { from: 'xl', value: -1, },
];

type Props = {
  biAction: ?() => void,
  itemData: TeaserDataType,
  index: number,
  hasBottomBorder: boolean,
  isLast: boolean,
};

const areas = `
"index    .    ."
"index content ."
"index footer  ."
`;

export default function MousepadTeaser({
  itemData,
  index,
  biAction,
  hasBottomBorder,
  isLast,
}: Props): React.Node {
  const { theme, css, } = useFela();
  const borderStyle = [ '2px', 1, 'solid', theme.color('neutral', '-6'), ];
  return (
    <Teaser
      areasTemplate={areas}
      colTemplate="auto 1fr 0"
      rowTemplate="0 1fr auto"
      gridGap="1rem 2rem"
      data={itemData}
      miscStyles={{
        minWidth: '8rem',
        paddingBottom: isLast ? [ { until: 's', value: '1rem', }, ] : null,
        borderBottom: [
          {
            until: 's',
            value: isLast ? null : borderStyle,
          },
          {
            from: 's',
            value: hasBottomBorder ? borderStyle : null,
          },
        ],
      }}
    >
      <div
        className={css({
          gridArea: 'index',
          color: theme.color('mousePad', 'index'),
          textAlign: 'center',
          fontWeight: 300,
          extend: [
            theme.type(9),
            theme.mq(
              { until: 's', },
              {
                paddingInlineEnd: '1rem',
                paddingInlineStart: '1rem',
              }
            ),
          ],
        })}
      >
        {index}
      </div>

      <TeaserHeader
        {...itemData}
        isGridItem
        typeScale={headerType}
        kickerTypeScale={headerType}
        onClick={biAction}
      />
      <TeaserFooter data={itemData} showCommentsCount={theme.mousepadStyle.teaser.footer.showCommentsCount} />
    </Teaser>
  );
}
