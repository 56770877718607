// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import type { Node, } from 'react';

import BoxyItem from './BoxyItem';
import BoxyMedia from './BoxyMedia';
import LayoutContainer from '../../../PageLayout/LayoutContainer';
import LayoutRow from '../../../PageLayout/LayoutRow';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';

import type { ImageDataType, } from '../../../../flowTypes/ImageDataType';
import type { HTMLEmbedDataType, } from '../../../../flowTypes/HTMLEmbedDataType';
import type { GalleryDataType, } from '../../../../flowTypes/GalleryDataType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListBiImpressionType, } from '../../../../flowTypes/ListBiImpressionType';
import { writeToLocalStorage, } from '../../../../utils/writeToStorage';

import useOneTime from '../../../../hooks/useOneTime';
import RainbowListPaywallSlot from '../../../Marketing/RainbowListPaywallSlot';

type MediaType = ImageDataType | HTMLEmbedDataType | GalleryDataType;

type Props = {
  list: ListDataType,
  listId: string,
  isLazyloadImages: boolean,
  gaAction: ?() => void,
  biAction: ?ListBiActionType,
  biImpression: ?ListBiImpressionType,
  hasMarginTop?: boolean,
  isColorInverse?: boolean,
  inMainBlock?: boolean,
  view?: string,
};

Boxy.defaultProps = {
  hasMarginTop: false,
  isColorInverse: false,
  inMainBlock: false,
  view: null,
};

export default function Boxy({
  list,
  isLazyloadImages,
  biAction,
  biImpression,
  gaAction,
  hasMarginTop, // ignored when `inMainBlock` is true
  isColorInverse,
  inMainBlock,
  inView,
  view,
}: Props): Node {
  const { theme, } = useFela();
  const [ isPaywallBlocked, setIsPaywallBlocked, ] = React.useState(false);
  const onRainbowToolRendered = () => setIsPaywallBlocked(true);

  const item: TeaserDataType = list.items[0];

  const image = item?.mobileImage || item?.image;

  const media: ?MediaType = item ? item.media || image : null;
  const { rainbowTargetSlot, } = list;

  useOneTime(item && !!biImpression && typeof biImpression === 'function' && !!inView, setBiImpression(0, item, biImpression));

  useOneTime(item, () => writeToLocalStorage({
    key: 'boxyExclude',
    // eslint-disable-next-line
    value: item?.contentId,
    errorMessage: 'error writing from last article id to local storage',
  })
  );

  return item && media && [ 'image', 'embed', ].includes(media.kind) ? (
    <LayoutRow
      bgc={isColorInverse ? 'transparent' : theme.boxyStyle.layoutRowBgc({ theme, })}
      miscStyles={inMainBlock ? {
        gridColumnEnd: [
          { from: 'l', value: 'span 2', },
        ],
        width: [ { until: 'l', value: 'auto', }, ],
        marginStart: [
          { until: 's', value: '-2rem', },
          { from: 's', until: 'l', value: '-4rem', },
        ],
        marginEnd: [
          { until: 's', value: '-2rem', },
          { from: 's', until: 'l', value: '-4rem', },
        ],
      } : {
        marginTop: [ { until: 's', value: '6rem', }, { from: 's', value: hasMarginTop ? '8rem' : '0', }, ],
      }}
      attrs={{
        'data-test': 'boxy',
      }}
    >
      <LayoutContainer miscStyles={{ display: 'grid', }}>
        { rainbowTargetSlot
          ? (
            <RainbowListPaywallSlot
              id={rainbowTargetSlot}
              gridArea="1 / 1 / -1 / -1"
              onToolRendered={onRainbowToolRendered}
            />
          )
          : null}
        <BoxyItem view={view} gridArea="1 / 1 / -1 / -1" item={item} isPaywallBlocked={isPaywallBlocked} wrapper={LayoutContainer} onClick={setBiAction(0, item, biAction)} isColorInverse={isColorInverse}>
          <BoxyMedia media={media} isLazyloadImages={isLazyloadImages} />
        </BoxyItem>
      </LayoutContainer>
    </LayoutRow>
  ) : null;
}
