// @flow
import React from 'react';
import { ELZAR_LIST_QUERY, } from '@haaretz/graphql';

import type { Node, } from 'react';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

import ElzarView from './ElzarView.js';

type Props = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
};

export default function Elzar(props: Props): Node {
  return (
    <ListWrapper {...props} query={ELZAR_LIST_QUERY} view="Elzar">
      {dataProps => <ElzarView {...dataProps} />}
    </ListWrapper>
  );
}
