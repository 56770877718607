// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import type { Node, } from 'react';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ClickTrackerBannerType, } from '../../../../flowTypes/ClickTrackerBannerType';
import type { ImageDataType, } from '../../../../flowTypes/ImageDataType';

import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import ClickTracker from '../../../ClickTracker/ClickTrackerWrapper';
import Image from '../../../Image/Image';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserLabelDisclaimer from '../../../TeaserLabelDisclaimer/TeaserLabelDisclaimer';

type ElzarPropsType = {
  list: ListDataType,
  isLazyloadImages: boolean,
  biAction: ?ListBiActionType,
  gaAction: ?() => void,
};

Elzar.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

const areasList = [
  {
    until: 'l',
    value: `
    "he"
    "t1"
    "t2"
    "t3"
    `,
  },
  {
    from: 'l',
    value: `
    "he he he"
    "t1 t2 t3"
    `,
  },
];
const colTemplateList = [ { from: 'l', value: '1fr 1fr 1fr', }, ];

export default function Elzar({
  list,
  isLazyloadImages,
  biAction,
  gaAction,
}: ElzarPropsType): Node {
  const { theme, } = useFela();
  const { title, banners, description, } = list;
  const { clickTrackerBanners, } = banners || {};

  return (
    <ListView
      areasTemplate={areasList}
      colTemplate={colTemplateList}
      attrs={{
        'data-test': 'elzar',
      }}
    >
      {/* Header */}
      <ListViewHeader
        title={title}
        isHorizontal
        isCommercial
        description={description}
        miscStyles={{ fontFamily: theme.fontStacks.commercial, }}
        backgroundColor="transparent"
      />
      {clickTrackerBanners
        && clickTrackerBanners.map((item, idx) => (idx < 3 ? (
          <ClickTracker
            key={item.contentId}
            {...item}
            render={(banner: ClickTrackerBannerType) => {
              const { name, link, image, advertiser, } = banner;
              return (
                <ElzarTeaser
                  companyName={advertiser}
                  banner={banner}
                  clicktrackerimage={image}
                  gridArea={`t${idx + 1}`}
                  link={link}
                  text={name}
                />
              );
            }}
          />
        ) : null)
        )}
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                         Teaser Component                          //
// /////////////////////////////////////////////////////////////////////

type TeaserPropsType = {
  banner: ClickTrackerBannerType,
  clicktrackerimage: ImageDataType,
  gridArea: string,
  isLazyloadImages: boolean,
  link: string,
  text: ?string,
  companyName?: string,
};

ElzarTeaser.defaultProps = {
  isLazyloadImages: true,
  companyName: null,
};

function ElzarTeaser({
  banner,
  clicktrackerimage,
  gridArea,
  isLazyloadImages,
  link,
  text,
  companyName,
}: TeaserPropsType): React.Node {
  const { theme, } = useFela();
  return (
    <Teaser
      gridArea={gridArea}
      areasTemplate={`
      "media    .    ."
      "media content ."
      "media label   ."
      "media    .    ."
      `}
      colTemplate={[
        { until: 's', value: '18rem 1fr', },
        { from: 's', until: 'l', value: '28rem 1fr 0', },
        { from: 'l', until: 'xl', value: '1fr 1fr 0', },
        { from: 'xl', value: '2fr 3fr 0', },
      ]}
      rowTemplate="0 1fr auto"
      gridGap="1rem"
      backgroundColor={[ 'commercial', 'bg', ]}
      miscStyles={{
        border: [ '1px', 0, 'solid', theme.color('neutral', '-5'), ],
        fontFamily: theme.fontStacks.commercial,
      }}
    >
      <TeaserMedia
        data={banner}
        isClickTracker
      >
        <Image
          lazyLoad={isLazyloadImages}
          image={clicktrackerimage}
          imgOptions={{
            transforms: { width: '154', aspect: 'regular', },
          }}
        />
      </TeaserMedia>

      <TeaserHeader
        isGridItem
        typeScale={[
          { until: 's', value: -1, },
          { from: 's', until: 'l', value: 1, },
          { from: 'l', value: -1, },
        ]}
        {...{
          title: text || '',
          path: link,
        }}
      />
      <TeaserLabelDisclaimer companyName={companyName || ''} />
    </Teaser>
  );
}
