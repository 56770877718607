// @flow
import * as React from 'react';
import { AMY_LIST_QUERY, } from '@haaretz/graphql';

import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListDataGetter from '../../ListDataGetter';
import AmyView from './AmyView.js';

type Props = {
  updateListDuplication: Function,
  variables: {},
  listData: ListDataType,
  viewProps: Object,
};

export default function Amy(props: Props): React.Node {
  return (
    <ListDataGetter query={AMY_LIST_QUERY} view="Amy" {...props}>
      {dataProps => (<AmyView {...dataProps} />)}
    </ListDataGetter>
  );
}
