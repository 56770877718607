// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import type { Node, } from 'react';

import H from '../../../AutoLevels/H';
import TeaserResponsiveText from '../../../TeaserResponsiveText/TeaserResponsiveText';
import Highlight from '../../../Highlight/Highlight';
import { BlockLinkInner, } from '../../../BlockLink/BlockLink';
import AboveBlockLink from '../../../BlockLink/AboveBlockLink';
import HtzLink from '../../../HtzLink/HtzLink';
import getTeaserHref from '../../../../utils/getTeaserHref';

import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

type FontWeightType = 600 | 400 | "bold" | "normal";

type Props = {
  item: TeaserDataType,
  children: Node,
  offset?: number,
  fontWeight?: FontWeightType,
  onClick?: ?Function,
  gridArea?: ?string,
  isColorInverse?: boolean,
  isPaywallBlocked?: boolean,
  view?: string,
};

BoxyItem.defaultProps = {
  offset: 1,
  fontWeight: 600,
  onClick: null,
  gridArea: null,
  isColorInverse: false,
  isPaywallBlocked: false,
  view: null,
};

export default function BoxyItem({
  item,
  children,
  offset,
  fontWeight,
  onClick,
  gridArea,
  isColorInverse,
  isPaywallBlocked,
  view,
}: Props): Node {
  const { css, theme, } = useFela({ fontWeight, view, });
  const href = getTeaserHref(item);
  return (
    <div
      {...(isPaywallBlocked ? { 'aria-hidden': true, inert: '', } : {})}
      className={css({
        // Hide yellow strip when it overflows from the bottom
        // because of subpixel rendering
        overflow: 'hidden',
        position: 'relative',
        gridArea: gridArea || null,
      })}
    >
      {children}
      <AboveBlockLink>
        {({ className, }) => (
          <div className={css(textWrapperStyle)}>
            {(item.exclusive || item.exclusiveMobile) && isColorInverse && (
              <Highlight
                isBlock
                highlightColor={[
                  'boxy',
                  isColorInverse ? 'innerTextBg' : 'innerText',
                ]}
                miscStyles={{
                  type: -1,
                  fontWeight,
                  color: [ 'boxy', isColorInverse ? 'innerText' : 'innerTextBg', ],
                }}
              >
                <TeaserResponsiveText
                  text={item.exclusive}
                  mobileText={item.exclusiveMobile}
                />
              </Highlight>
            )}
            {item.title && (
              // We use an offset here, because the title should be the same level
              // as a header inside a section, no the same as a section's title
              <H className={view === 'BoxyInverseHdc' ? css(headlineStyleBoxyInverseHdc) : css(headlineStyle)} offset={offset}>
                {view === 'BoxyInverseHdc'
                  ? (
                    <div className={css(hdcInverseTextStyle)}>
                      <TeaserResponsiveText
                        text={item.title}
                        mobileText={item.titleMobile}
                      />
                    </div>
                  )
                  : (
                    <HtzLink href={href} className={className} onClick={onClick}>
                      <Highlight
                        highlightColor={[
                          'boxy',
                          isColorInverse ? 'innerText' : 'innerTextBg',
                        ]}
                        miscStyles={{
                          color: theme.color(
                            'boxy',
                            isColorInverse ? 'innerTextBg' : 'innerText'
                          ),
                          fontWeight,
                          opacity: '0.92',
                        }}
                      >
                        <TeaserResponsiveText
                          text={item.title}
                          mobileText={item.titleMobile}
                        />
                      </Highlight>
                    </HtzLink>
                  )}
              </H>
            )}
          </div>
        )}
      </AboveBlockLink>
      {view === 'BoxyInverseHdc' ? null
        : <BlockLinkInner href={href} onClick={onClick} />
      }
    </div>
  );
}

// /////////////////////////////////////////////////////////////////////
//                               STYLE                                //
// /////////////////////////////////////////////////////////////////////

function hdcInverseTextStyle({ theme, }) {
  return {
    display: 'inline',
    boxDecorationBreak: 'clone',
    color: theme.color(
      'boxy',
      'innerText',
    ),
    backgroundColor: theme.color(
      'boxy',
      'innerTextBg',
    ),
    padding: '4px 2rem',
    lineHeight: '5rem',
    opacity: '0.92',
  };
}

function textWrapperStyle({ theme, view, }) {
  return {
    position: 'absolute',
    start: 0,
    bottom: 0,
    maxWidth: view === 'BoxyInverseHdc' ? '75%' : '90%',
  };
}

function headlineStyle({ theme, }) {
  return {
    extend: theme.boxyStyle.boxyItem({ theme, }).themeType,
  };
}

function headlineStyleBoxyInverseHdc({ theme, }) {
  return {
    fontSize: '4rem',
    lineHeight: '1.5',
  };
}
