/* global window */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import BasePlaceholder from '../BasePlaceholder/BasePlaceholder';
import type { IframeType, } from '../../flowTypes/IframeType';

export default function Iframe({
  src,
  contentName,
  elementId,
  ...props
}: IframeType): React.Node {
  const [ iframeWasLoaded, setIframeWasLoaded, ] = React.useState(false);
  const [ height, setHeight, ] = React.useState(`${props.height}px`);
  const { css, } = useFela();

  const iframeRef = React.useRef(null);

  React.useEffect(() => {
    function handleMessage(event: MessageEvent): void {
      if (
        event.data
        && typeof event.data === 'object'
        && event.data.id === elementId
      ) {
        if (height !== event.data.height) setHeight(event.data.height);
        if (!iframeWasLoaded) {
          setIframeWasLoaded(true);
        }
      }
    }

    window.addEventListener('message', handleMessage, false);
    return () => window.removeEventListener('message', handleMessage);
  }, [ elementId, height, iframeWasLoaded, ]);

  React.useEffect(() => {
    function handleLoad(event: Event): void {
      setIframeWasLoaded(true);
    }
    const { current: iframeEl, } = iframeRef;
    if (iframeEl && !iframeWasLoaded) {
      iframeEl.addEventListener('load', handleLoad);
    }
    return () => iframeEl && iframeEl.removeEventListener('load', handleLoad);
  }, [ iframeWasLoaded, ]);

  return (
    <div className={css({ position: 'relative', height, })}>
      <iframe
        ref={iframeRef}
        style={{ position: 'relative', zIndex: 1, }}
        src={src}
        width="100%"
        height={height}
        title={contentName}
        frameBorder="0"
        scrolling="no"
      />
      <BasePlaceholder isHidden={iframeWasLoaded} isAbsolute />
    </div>
  );
}
