// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { type StyleProps, type ComponentPropResponsiveObject, parseStyleProps, } from '@haaretz/htz-css-tools';

import IconClock2 from '../Icon/icons/IconClock2';

import { calcReadingTime, } from '../../utils/calcReadingTime';
import { toHumanTimeString, } from '../../utils/time';


type PropsType = {
    articleWordCount: ?number,
    text?: ?string,
    miscStyles: ?StyleProps,
    iconMiscStyle: ?StyleProps,
    iconSize: ?number | ComponentPropResponsiveObject<number>[],
};

TeaserReadingTime.defaultProps = {
  articleWordCount: null,
  text: null,
  miscStyles: null,
  iconMiscStyle: null,
  iconSize: 2,
};

function styles({ theme, miscStyles, }) {
  return {
    verticalAlign: 'middle',
    extend: [
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  };
}

export default function TeaserReadingTime({ articleWordCount, text, iconSize, miscStyles, iconMiscStyle, }: PropsType) {
  const { css, theme, } = useFela({ miscStyles, });
  const readingTime = articleWordCount ? calcReadingTime(articleWordCount) : null;
  return readingTime
    ? (
      <time className={css(styles)}>
        <IconClock2 size={iconSize} miscStyles={{ marginInlineEnd: '1rem', ...iconMiscStyle, }} />
        <span>
          {text ? `${text} ` : null}
          {toHumanTimeString(readingTime, 'minutes', 'auto', theme.timeDuration)}
        </span>
      </time>
    )
    : null;
}
