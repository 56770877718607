// @flow
import * as React from 'react';
import { HAWKING_LIST_QUERY, } from '@haaretz/graphql';
import HawkingView from './HawkingView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';
// eslint-disable-next-line import/no-extraneous-dependencies

type HawkingWrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
}

export default function HawkingWrapper(props: HawkingWrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={HAWKING_LIST_QUERY} view="Hawking">
      {dataProps => <HawkingView {...dataProps} />}
    </ListWrapper>
  );
}
