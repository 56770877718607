const container: Object = Object.freeze({
  areasTemplate: Object.freeze([
    Object.freeze({
      until: 's', value: `
          "he"
          "t1"
          "t2"
          "t3"
          "t4"
          "ad"
        `,
    }),
    Object.freeze({
      from: 's', until: 'l', value: `
            "he he"
            "t1 t1"
            "t2 ad"
            "t3 ad"
            "t4 ad"
            "ad2 ad2"
            `,
    }),
    Object.freeze({
      from: 'l', until: 'xl', value: `
            "he t1 t1 ad2"
            "he t2 ad ad2"
            "he t3 ad ad2"
            "he t4 ad ad2"
            `,
    }),
    Object.freeze({
      from: 'xl', value: `
           "he t1 t2 ad"
           "he t1 t3 ad"
           "he t1 t4 ad"
          `,
    }),
  ]),
  rowTemplate: Object.freeze([
    Object.freeze({
      from: 'l', until: 'xl', value: 'max-content',
    }),
  ]),
  colTemplate: Object.freeze([
    Object.freeze({
      from: 's', until: 'l', value: '13fr 9fr',
    }),
    Object.freeze({
      from: 'l', value: '2fr 4fr 3fr 3fr',
    }),
  ]),
});

const mainTeaser: Object = Object.freeze({
  areasTemplate: Object.freeze([
    Object.freeze({
      until: 's', value: `
          "media media media"
          ".    content    ."
          ".    footer     ."
          ".      .        ."
          `,
    }),
    Object.freeze({
      from: 's', until: 'xl', value: `
            "media .    .    ."
            "media . content ."
            "media . footer  ."
            "media .    .    ."
            `,
    }),
    Object.freeze({
      from: 'xl', value: `
           "media media media"
           ".    content   ."
           ".    footer    ."
           ".      .       ."
          `,
    }),
  ]),
  colTemplate: Object.freeze([
    Object.freeze({
      until: 's', value: '0 1fr 0',
    }),
    Object.freeze({
      from: 's', until: 'l', value: '11fr 0 5fr 0',
    }),
    Object.freeze({
      from: 'l', until: 'xl', value: '3fr 0 1fr 0',
    }),
    Object.freeze({
      from: 'xl', value: '0 1fr 0',
    }),
  ]),
  rowTemplate: Object.freeze([
    Object.freeze({
      until: 's', value: 'auto 1fr auto 0',
    }),
    Object.freeze({
      from: 's', until: 'xl', value: '0 1fr auto 0',
    }),
    Object.freeze({
      from: 'xl', value: 'auto 1fr auto 0',
    }),
  ]),
});

const defaultTeaser: Object = Object.freeze({
  areasTemplate: `
  ".    .    ."
  ". content ."
  ". footer  ."
  ".   .     ."
  `,
  colTemplate: '0 1fr 0',
  rowTemplate: '0 1fr auto 0',
});

export {
  container, mainTeaser, defaultTeaser,
};
