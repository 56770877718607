// @flow
import React from 'react';

import type { Node, } from 'react';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';

import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import AdSlot from '../../../AdManager/AdSlot';

type ButtheadPropsType = {
  list: ListDataType,
  isLazyloadImages: boolean,
  biAction: ?ListBiActionType,
  gaAction: ?() => void,
};

Butthead.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

function Butthead({
  list,
  isLazyloadImages,
  biAction,
  gaAction,
}: ButtheadPropsType): Node {
  const { title, banners, } = list;
  const { adSlots, } = banners || {};

  return (
    <ListView
      colTemplate="1fr 1fr"
      padding="0 4rem"
      gridGap={null}
      rowGap="1rem"
      colGap="4rem"
      sectionMiscStyles={{
        display: [ { until: 'l', value: 'none', }, ],
        '& iframe': { width: '100%', },
      }}
      attrs={{
        'data-test': 'butthead',
      }}
    >
      {/* Header */}
      <ListViewHeader title={title} isHorizontal isCommercial miscStyles={{ gridArea: '1 / 1 / 2 / 3', }} />

      {/* Items */}
      {Array.isArray(adSlots) && (
        adSlots.slice(0, 2).map(data => (
          <AdSlot key={data.contentId} {...data} />
        ))
      )}
    </ListView>
  );
}

export default Butthead;
