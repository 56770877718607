// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';

import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListBiImpressionType, } from '../../../../flowTypes/ListBiImpressionType';

import Image from '../../../Image/Image';
import ListView from '../../../ListView/NewListView';
import Picture from '../../../Image/Picture';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import getImageAssets from '../../../../utils/getImageAssets';
import getPictureAssets from '../../../../utils/getPictureAssets';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';
import useOneTime from '../../../../hooks/useOneTime';

type Props = {
  isLazyloadImages: boolean,
  isWideMain: boolean,
  list: ListDataType,
  biAction: ?ListBiActionType,
  biImpression: ?ListBiImpressionType,
  inView: boolean,
};

Pazuzu.defaultProps = {
  isWideMain: false,
  isLazyloadImages: false,
};

export default function Pazuzu({
  isWideMain,
  list,
  isLazyloadImages,
  biAction,
  biImpression,
  inView,
}: Props): React.Node {
  const { theme, } = useFela();
  const { items, } = list || {};

  useOneTime(items && !!biImpression && typeof biImpression === 'function' && !!inView, () => items.map((item, i) => {
    i < 2 && biImpression && typeof setBiImpression === 'function' && setBiImpression(i, item, biImpression)();
    return null;
  }));

  if (!items || !items.length) return null;
  return (
    <ListView
      disableWrapper
      keepHeadingLevel
      colGap={[ { until: 's', value: '2', }, { from: 's', value: '4', }, ]}
      rowGap={null}
      gridGap={null}
      colTemplate="1fr 1fr"
      outerBackgroundColor={[ { from: 's', value: 'white', }, ]}
      miscStyles={isWideMain ?
        { paddingEnd: [ { from: 'l', value: '4rem', }, ],} :
        { gridColumnEnd: [ { from: 'xl', value: 'span 2', }, ], paddingEnd: [ { from: 'l', until: 'xl', value: '4rem', }, ], }}
      padding={null}
      attrs={{
        'data-test': 'pazuzu',
      }}
    >
      {items.slice(0, 2).map((item, i) => (
        <PazuzuTeaser
          key={item.contentId}
          isLazyloadImages={isLazyloadImages}
          isStackedOnXl={isWideMain}
          item={item}
          biAction={setBiAction(i, item, biAction)}
          theme={theme}
        />
      )
      )}
    </ListView>
  );
}

type PazuzuTeaserPropTypes = {
  item: TeaserDataType,
  isStackedOnXl: boolean,
  isLazyloadImages: boolean,
  theme: Object,
  biAction: ?() => void,
};

const areasTeaser = `
"media content"
"media  footer"
"media     ..."
`;
const areasStackedTeaser = `
"media media   media"
"...   content   ..."
"...   footer    ..."
`;

PazuzuTeaser.defaultProps = { isLazyloadImages: false, };
function PazuzuTeaser({
  item,
  isStackedOnXl,
  isLazyloadImages,
  biAction,
  theme,
}: PazuzuTeaserPropTypes): React.Node {
  const { teaser, } = theme.pazuzuStyle;
  const image = item?.mobileImage || item?.image;

  return (
    <Teaser
      areasTemplate={[
        { until: 'xl', value: areasStackedTeaser, },
        { from: 'xl', value: isStackedOnXl ? areasStackedTeaser : areasTeaser, },
      ]}
      colTemplate={[
        { until: 'xl', value: '0 1fr 0', },
        { from: 'xl', value: isStackedOnXl ? '0 1fr 0' : '48rem 1fr', },
      ]}
      rowTemplate={[
        { until: 'xl', value: 'auto 1fr auto', },
        { from: 'xl', value: isStackedOnXl ? 'auto 1fr auto' : 'auto auto 1fr', },
      ]}
      rowGap="1rem"
      colGap={[
        { until: 's', value: '1rem', },
        { from: 's', until: 'xl', value: '0', },
        { from: 'xl', value: isStackedOnXl ? '0' : '2rem', },
      ]}
      gridGap={null}
      onClick={biAction}
    >
      <TeaserMedia data={item} onClick={biAction}>
        {isStackedOnXl ? (
          <Picture
            lazyLoad={isLazyloadImages}
            {...getPictureAssets({
              bps: theme.bps,
              imgData: image,
              defaultImgOptions: {
                sizes: [
                  { from: 'l', size: '314px', },
                  { from: 'm', size: '348px', },
                  { from: 's', size: '264px', },
                  { size: 'calc(50vw - 36px)', },
                ],
                aspect: 'headline',
                widths: [ 170, 265, 314, 350, 600, ],
              },
              sources: [
                {
                  aspect: 'landscape',
                  from: 'xl',
                  sizes: [ { size: '389px', }, ],
                  widths: [ 389, ],
                },
              ],
            })}
          />
        ) : (
          <Image
            lazyLoad={isLazyloadImages}
            image={image}
            imgOptions={getImageAssets({
              bps: theme.bps,
              aspect: 'headline',
              sizes: [
                { from: 'xl', size: '336px', },
                { from: 'l', size: '314px', },
                { from: 'm', size: '348px', },
                { from: 's', size: '264px', },
                { size: 'calc(50vw - 36px)', },
              ],
              widths: [ 170, 200, 240, 265, 280, 315, 350, 600, ],
            })}
          />
        )}
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        {...item}
        typeScale={teaser.headerTypeScale}
        onClick={biAction}
        miscStyles={{
          paddingEnd: '1rem',
        }}
      />
      <TeaserFooter
        data={item}
        showAuthor
        showLive={!!item.liveUpdates}
        showRank={teaser.footer.showRank}
        showMediaFlags
        noRankOnMobile
        noCommentsOnMobile
        showTime={teaser.footer.showTime}
        showCommentsCount={teaser.footer.showCommentsCount}
        miscStyles={{ paddingBottom: [ { until: 's', value: '1rem', }, ], }}
        typeScale={[
          { until: 's', value: -3, },
          { from: 's', until: 'xl', value: -2, },
          { from: 'xl', value: -3, },
        ]}
      />
    </Teaser>
  );
}
