// @flow
import * as React from 'react';
import { SCHOONHOVEN_LIST_QUERY, } from '@haaretz/graphql';
import SchoonhovenView from './SchoonhovenView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type SchoonhovenWrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
}

export default function SchoonhovenWrapper(props: SchoonhovenWrapperProps) {
  return (
    <ListWrapper {...props} query={SCHOONHOVEN_LIST_QUERY} view="Schoonhoven">
      {dataProps => <SchoonhovenView {...dataProps} />}
    </ListWrapper>
  );
}
