// @flow
import { useFela, } from 'react-fela';
import * as React from 'react';

import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { ClickTrackerBannerWrapperType, } from '../../../../flowTypes/ClickTrackerBannerWrapperType';
import type { ClickTrackerBannerType, } from '../../../../flowTypes/ClickTrackerBannerType';
import type { attrFlowType, } from '../../../../flowTypes/attrTypes';


import Image from '../../../Image/Image';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserLabelDisclaimer from '../../../TeaserLabelDisclaimer/TeaserLabelDisclaimer';
import getImageAssets from '../../../../utils/getImageAssets';
import ClickTracker from '../../../ClickTracker/ClickTrackerWrapper';
import setBiAction from '../../../../utils/setBiAction';
import { isClickTracker, isClickTrackerWrapper, isTeaser, } from '../../../../utils/validateType';
import useMedia from '../../../../hooks/useMedia';


type Props = {
  list: ?ListDataType,
  gaAction: ?() => void,
  biAction: ?ListBiActionType,
  banners: ?Array<ClickTrackerBannerWrapperType>,
  isCommercial: ?boolean,
  title: ?string | ?Array<string>,
  gridArea: ?string,
  attrs: ?attrFlowType,
};

VerticalList.defaultProps = {
  banners: null,
  isCommercial: false,
  list: null,
  title: null,
  biAction: null,
  gridArea: null,
  attrs: null,
};

export default function VerticalList({
  list,
  gaAction,
  biAction,
  banners,
  title,
  gridArea,
  isCommercial: isCommercialProp,
  attrs,
}: Props) {
  const isCommercial = isCommercialProp || !!banners;
  const { theme, css, } = useFela();
  const items = (banners?.length > 0 && banners) || (list?.items?.length > 0 && list.items) || [];
  const allowSubtitle = useMedia({ query: [ { until: 's', }, { from: 'xl', }, ], });

  const promotionText = theme.clickTrackerI18n.promotedContentLabel;

  const finalTitle = Array.isArray(title) && title.length
    ? allowSubtitle ? title.slice(0, 2) : title[0]
    : title || (list && list.title) || (isCommercial && promotionText) || '';

  return (
    <ListView
      keepHeadingLevel
      gridGap={null}
      colGap="1rem"
      rowGap={[ { until: 's', value: '2rem', }, { from: 's', value: '1rem', }, ]}
      rowTemplate="max-content"
      padding="0"
      marginTop="0"
      outerBackgroundColor={isCommercial ? [ 'commercial', 'bg', ] : 'white'}
      sectionMiscStyles={{
        display: 'grid',
        gridArea,
        ...(isCommercial ? { fontFamily: theme.fontStacks.commercial,
          border: [ '1px', 0, 'solid', theme.color('neutral', '-4'), ], } : {}),
      }}
      attrs={attrs}
    >
      {list || title ? (
        <ListViewHeader
          url={list ? list.url : null}
          biAction={biAction}
          isHorizontal
          isVertical
          hasTitlePadding
          title={finalTitle}
          backgroundColor={isCommercial ? [ 'commercial', 'bg', ] : 'white'}
          isCommercial={isCommercial}
          miscStyles={{
            gridArea: '1 / 1 / 2 / 2',
            marginBottom: [ { until: 's', value: '-2rem', }, { from: 's', value: '-1rem', }, ],
          }}
          isSticky
        />
      ) : null}
      <Section isFragment>
        {items && items.map((item, index) => (
          isClickTrackerWrapper(item) ? (
            <ClickTracker
              key={item.contentId || item.contentName}
              {...item}
              render={(banner: ClickTrackerBannerType) => (
                <VerticalListTeaser
                  index={index}
                  isLast={index === items.length - 1}
                  theme={theme}
                  itemData={banner}
                  biAction={biAction}
                  isLazyloadImages
                  isCommercial={isCommercial}
                />
              )}
            />
          ) : isTeaser(item) ? (
            <VerticalListTeaser
              index={index}
              isLast={index === items.length - 1}
              theme={theme}
              key={item.contentId}
              itemData={item}
              biAction={biAction}
              isLazyloadImages
              isCommercial={isCommercial}
            />
          ) : null
        ))}
      </Section>
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                              TEASERS                               //
// /////////////////////////////////////////////////////////////////////

type FirstTeaserProps = {
  isLazyloadImages?: boolean, // eslint-disable-line react/no-unused-prop-types
  itemData: TeaserDataType | ClickTrackerBannerType,
  biAction: ?ListBiActionType,
  index: number,
  isLast: boolean,
  theme: Object,
  isCommercial: boolean,
};

VerticalListTeaser.defaultProps = { isLazyloadImages: true, isCommercial: false, };


const areasFirstTeaser = `
"media media media"
".    content    ."
".    label      ."
`;

const areasDefault = '". content ." ". label ."';


function VerticalListTeaser({
  isLazyloadImages,
  itemData,
  index,
  isLast,
  biAction,
  theme,
  isCommercial, }: FirstTeaserProps) {
  const onClick = setBiAction(index, itemData, biAction);
  const isFirst = index === 0;

  const image = itemData?.mobileImage || itemData?.image;

  return (
    <Teaser
      gridGap={null}
      colGap="1rem"
      rowGap={[ { until: 's', value: '2rem', }, { from: 's', value: '1rem', }, ]}
      areasTemplate={isFirst ? areasFirstTeaser : areasDefault}
      colTemplate="0 1fr 0"
      rowTemplate={isFirst ? 'auto 1fr' : null}
      isClickTracker={isClickTracker(itemData)}
      backgroundColor={isCommercial ? [ 'commercial', 'bg', ] : 'white'}
    >
      {isFirst && (
        <TeaserMedia
          data={itemData}
          isClickTracker={isClickTracker(itemData)}
          onClick={onClick}
        >
          <Image
            lazyLoad={isLazyloadImages}
            image={isClickTracker(itemData) ? itemData.clicktrackerimage : image}
            imgOptions={getImageAssets({
              aspect: 'headline',
              bps: theme.bps,
              sizes: [
                { from: 'xl', size: '295px', },
                { from: 'l', size: '238', },
                { from: 'm', size: '372', },
                { from: 's', size: '288', },
                { size: 'calc(100vw - 24px)', },
              ],
              widths: [ 744, 372, 295, 288, 238, ],
            })}
          />
        </TeaserMedia>
      )}
      <TeaserHeader
        isGridItem
        {...{
          ...itemData,
          title: isClickTracker(itemData) ? itemData.text || '' : itemData.title,
          path: isClickTracker(itemData) ? itemData.link : itemData.path,
        }}
        typeScale={theme.verticalList.teaser.headerTypeScale(isFirst)}
        kickerTypeScale={theme.verticalList.teaser.kickerTypeScale(isFirst)}
        onClick={onClick}
        wrapperMiscStyles={{
          ...(isLast
            ? { paddingBottom: '1rem', }
            : !itemData.advertiser && !isCommercial ? {
              borderBottom: [ '1px', 1, 'solid', theme.color('neutral', '-5'), ],
            } : null),
          ...theme.mq({ until: 's', }, { paddingBottom: '2rem', }),
        }}
        miscStyles={isFirst ? null : { fontWeight: '400', }}
      />

      {isCommercial
        ? (
          <TeaserLabelDisclaimer
            companyName={itemData.advertiser || ''}
            miscStyles={
              {
                paddingBottom: '1rem',
                ...(isLast
                  ? {}
                  : {
                    borderBottom: [ '1px', 0, 'solid', theme.color('neutral', '-5'), ],
                  }),
              }}
          />
        ) : null}
    </Teaser>
  );
}
