// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

type PropsType = {
    articleWordCount: ?number,
    text?: ?string,
    miscStyles: ?StyleProps,

};

TeaserReadingArticleCount.defaultProps = {
  articleCount: null,
  miscStyles: null,
  text: null,
};

function styles({ theme, miscStyles, }) {
  return {
    verticalAlign: 'middle',
    fontFamily: theme.fontStacks.alt,
    lineHeight: '2em',
    display: 'inline-block',
    extend: [
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  };
}

export default function TeaserReadingArticleCount({ articleCount, text, miscStyles, }: PropsType) {
  const { css, } = useFela({ miscStyles, });

  return articleCount || text
    ? (
      <span className={css(styles)}>
        {text ? `${text} ${articleCount} ` : `${articleCount} articles`}
      </span>
    )
    : null;
}
