// @flow
import * as React from 'react';

import ListView from '../../../ListView/NewListView.js';
import MousepadTeaser from './MousepadTeaser';
import setBiAction from '../../../../utils/setBiAction';

import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type Props = {
  list: ListDataType,
  isLazyloadImages: boolean,
  gaAction: () => void,
  biAction: ?ListBiActionType,
};

export default function Mousepad({
  list,
  gaAction,
  biAction,
  isLazyloadImages,
}: Props): React.Node {
  const items = list.items || [];
  const listMiddle = Math.ceil(items.length / 2);

  return (
    <ListView
      innerBackgroundColor="white"
      marginTop="0rem"
      rowTemplate={[ { from: 's', value: 'auto auto auto', }, ]}
      colTemplate={[ { from: 's', value: '1fr 1fr', }, ]}
      gridGap={null}
      rowGap="1rem"
      colGap="4rem"
      padding="2rem 1rem 1rem"
      miscStyles={{
        gridAutoFlow: [ { from: 's', value: 'column', }, ],
      }}
      attrs={{
        'data-test': 'mousepad',
      }}
    >
      {items.map((item, i) => {
        const index = i + 1;
        return (
          <MousepadTeaser
            index={index}
            key={item.contentId}
            itemData={item}
            biAction={setBiAction(i, item, biAction)}
            hasBottomBorder={index % listMiddle !== 0}
            isLast={index === items.length}
          />
        );
      })}
    </ListView>
  );
}
