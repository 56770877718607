// @flow
import * as React from 'react';
import { ROBERTO_LIST_QUERY, } from '@haaretz/graphql';

import RobertoView from './RobertoView.js';
import ListWrapper from '../../ListWrapper';

import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type RobertoWrapperProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
}

export default function RobertoWrapper(props: RobertoWrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={ROBERTO_LIST_QUERY} view="Roberto">
      {dataProps => <RobertoView {...dataProps} />}
    </ListWrapper>
  );
}
