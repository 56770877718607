// @flow
import * as React from 'react';
import { PAZUZU_LIST_QUERY, } from '@haaretz/graphql';
import PazuzuView from './PazuzuView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type PazuzuWrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
}

export default function PazuzuWrapper(props: PazuzuWrapperProps) {
  return (
    <ListWrapper {...props} query={PAZUZU_LIST_QUERY} view="Pazuzu">
      {dataProps => <PazuzuView {...dataProps} />}
    </ListWrapper>
  );
}
