// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import ListView from '../ListView/NewListView';
import Teaser from '../Teaser/NewTeaser';
import TeaserHeader from '../TeaserHeader/TeaserHeader';

import EventTracker from '../../utils/EventTracker';
import setBiAction from '../../utils/setBiAction';

import type { TeaserDataType, } from '../../flowTypes/TeaserDataType';
import getImageAssets from '../../utils/getImageAssets';
import TeaserMedia from '../TeaserMedia/NewTeaserMedia';
import Image from '../Image/Image';

type RelatedArticlesTeasersProps ={
  relatedArticles: ?TeaserDataType[],
}

RelatedArticlesTeasers.defaultProps = {
  relatedArticles: null,
};


export default function RelatedArticlesTeasers({
  relatedArticles, }: RelatedArticlesTeasersProps) {
  if (!relatedArticles) return null;
  return (
    <EventTracker>
      {({ biAction, }) => (
        <ListView
          innerBackgroundColor="white"
          outerBackgroundColor="white"
          disableWrapper
          colTemplate={[ { from: 's', value: 'repeat(3, 1fr)', }, ]}
          padding={[ { until: 's', value: '0rem 2rem 4rem', }, { from: 's', value: '2rem', }, ]}
          miscStyles={{
            marginTop: '-2rem',
            paddingBottom: '2rem',
          }}
        >
          {relatedArticles.map((item, i) => (
            <RelatedArticlesTeaser
              key={item.contentId}
              item={item}
              biAction={setBiAction(i, item, biAction)}
            />
          ))}
        </ListView>
      )}
    </EventTracker>
  );
}


const areasTemplate = [
  {
    until: 'l',
    value: `
    " .  "
    "content"
    "  .  "
    `,
  },
  {
    from: 'l',
    value: `
    "media .   .     "
    "media . content "
    "media  .   .    "
    `,
  },
];

const colTemplate = [
  { until: 'l', value: '1fr', },
  { from: 'l', until: 'xl', value: '10rem 2rem 1fr', },
  { from: 'xl', value: '9rem 4rem 1fr', },
];


type RelatedArticlesTeaserProps = {
  item: TeaserDataType,
  biAction: ?() => void,
};

export function RelatedArticlesTeaser({ item, biAction, }: RelatedArticlesTeaserProps) {
  const { theme, } = useFela();
  if (!item) return null;
  return (
    <Teaser
      data={item}
      areasTemplate={areasTemplate}
      colTemplate={colTemplate}
      rowTemplate="0 1fr 0"
    >
      <TeaserHeader
        {...item}
        isGridItem
        typeScale={[
          { until: 's', value: { step: -1, lines: 3, }, },
          { from: 's', value: { step: -1, lines: 3.5, }, },
        ]}
        onClick={biAction}
        wrapperMiscStyles={{
          display: [ { from: 'l', value: 'flex', }, ],
          alignItems: [ { from: 'l', value: 'center', }, ],
        }}
      />
      <TeaserMedia
        data={item}
        miscStyles={{
          borderRadius: '50%',
          display: [ { until: 'l', value: 'none', }, ],
          width: [ { from: 'l', until: 'xl', value: '10rem', },
            { from: 'xl', value: '9rem', }, ],
          alignSelf: 'center',
        }}
      >
        <Image
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'square',
            sizes: [ { size: '80px', }, ],
            widths: [ 80, ],
          })}
          image={item.image}
          miscStyles={{ borderRadius: '50%', overflow: 'hidden', }}
        />

      </TeaserMedia>
    </Teaser>
  );
}
