// @flow
import * as React from 'react';
// Conrad and Wong use the exact same query
import { WONG_LIST_QUERY as CONARD_LIST_QUERY, } from '@haaretz/graphql';
import ConradView from './ConradView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';


type ConradWrapperProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isDark?: boolean,
}

ConradWrapper.defaultProps = {
  isDark: false,
};

export default function ConradWrapper(props: ConradWrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={CONARD_LIST_QUERY} view="Conrad">
      {dataProps => (
        <ConradView
          {...dataProps}
          isDark={props?.isDark}
        />
      )}
    </ListWrapper>
  );
}
