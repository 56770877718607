// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import { useInView, } from 'react-intersection-observer';

import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import Image from '../../../Image/Image';
import HtzLink from '../../../HtzLink/HtzLink';
import IconRss from '../../../Icon/icons/IconRss';
import SchoonhovenAudioSkin from './SchoonhovenAudioSkin';
import RelatedArticlesTeasers from '../../../RelatedArticlesTeasers/RelatedArticlesTeasers';
import SchoonhovenLabelAudioSkin from './SchoonhovenLabelAudioSkin';

import usePodcastChannelType from '../../../../hooks/usePodcastChannelType';
import getImageAssets from '../../../../utils/getImageAssets';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';
import useOneTime from '../../../../hooks/useOneTime';

import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListBiImpressionType, } from '../../../../flowTypes/ListBiImpressionType';

import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type Props = {
  list: ListDataType,
  listId: string,
  isLazyloadImages: boolean,
  gaAction: ?() => void,
  biAction: ?ListBiActionType,
  biImpression: ?ListBiImpressionType,
  isCommercial?: boolean,
};

type SchoonhovenRssLinkProps = {
  podcastChannelLinks: { google: string, apple: string, spotify: string, },
  isCommercial?: boolean,
};

const areasTemplate = `
"he"
"teaser"
`;

const teaserAreasTemplate = [
  {
    until: 's',
    value: `
    "content"
    "podcast"
    "rss"
    `,
  },
  {
    from: 's',
    value: `
    "media content"
    "media podcast"
    `,
  },
];

const teaserColTemplate = [
  { from: 'xl', value: '12rem 1fr', },
  { from: 'm', until: 'xl', value: '15rem 1fr', },
  { from: 's', until: 'm', value: '17rem 1fr', },
];

const commonRssLinkStyles = ({ theme, isMobile = false, isCommercial, }) => {
  const color = isCommercial ? theme.color('commercial', 'base')
    : theme.color('schoonhoven', `rssLink${isMobile ? 'Mobile' : ''}`);
  return {
    color,
    ':hover': {
      color,
    },
    ':visited': {
      color,
    },
    ':active': {
      color,
    },
    ':focus': {
      color,
    },
    type: -2,
    fontWeight: 400,
  };
};

const SchoonhovenRssLinkInner = ({ theme, }: { theme: Object, }): React.Node => (
  <>
    <IconRss size={[ { until: 'xl', value: 3.7, }, { from: 'xl', value: 3.1, }, ]} miscStyles={{ marginEnd: '0.5rem', }} />
    {theme.podcastI18n.rssLinkText}
  </>
);
SchoonhovenRssLink.defaultProps = {
  isCommercial: false,
};

function SchoonhovenRssLink({
  podcastChannelLinks,
  isCommercial, }: SchoonhovenRssLinkProps): React.Node {
  const { css, theme, } = useFela();
  const channel = usePodcastChannelType();
  const href = podcastChannelLinks[channel];

  return (
    <HtzLink
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      className={css({
        fontFamily: theme.fontStacks[theme.framedFont],
        gridArea: 'rss',
        paddingTop: '2rem',
        justifySelf: 'center',
        extend: [
          theme.mq({ from: 's', }, { display: 'none', }),
          ...parseStyleProps(commonRssLinkStyles({ theme, isMobile: true, isCommercial, }), theme.mq, theme.type),
        ],
      })}
    >
      <SchoonhovenRssLinkInner theme={theme} />
    </HtzLink>
  );
}

SchoonhovenView.defaultProps = {
  isCommercial: false,
};

function SchoonhovenView({
  list,
  listId,
  isLazyloadImages,
  biAction,
  biImpression,
  gaAction,
  isCommercial,
}: Props): React.Node {
  const { theme, } = useFela();
  const [ ref, inView, ] = useInView({ threshold: 0.3, triggerOnce: true, });
  const item = list.items[0];

  const relatedArticles = list.items.slice(1, 4);
  const displayRelatedArticles = relatedArticles.length === 3;
  useOneTime(!!inView && !!biImpression, () => {
    setBiImpression(0, item, biImpression)();
    relatedArticles.map((data, i) => {
      setBiImpression(i + 1, data, biImpression)();
      return null;
    });
  });
  if (!item || !item.media || !item.media.channel) return null;


  const { media, contentId, } = item;
  const { url, title, channel, } = media;
  const { podcastChannelLinks, channelName, } = channel || {};

  if (!url) return null;

  const onClick = setBiAction(0, item, biAction);

  const image = item?.mobileImage || item?.image;

  return (
    <ListView
      areasTemplate={areasTemplate}
      gridGap={null}
      rowGap="2rem"
      marginTop={[ { until: 's', value: isCommercial ? 2 : 6, }, { from: 's', value: 5, }, ]}
      attrs={{
        'data-test': 'schoonhoven',
      }}
      padding={[ { until: 's', value: '0rem 2rem', }, { from: 's', value: '0', }, ]}
    >
      <ListViewHeader
        isHorizontal
        isCommercial={isCommercial}
        {...list}
        extraLinksMiscStyles={commonRssLinkStyles({ theme, isMobile: false, isCommercial, })}
        {...(podcastChannelLinks ? {
          extraLinks: [
            {
              href: podcastChannelLinks.spotify,
              linkText: <SchoonhovenRssLinkInner theme={theme} />,
              contentId: `${listId}/${contentId}`,
              openNewTab: true,
            },
          ],
        } : {})}
      />
      <Teaser
        fwRef={ref}
        areasTemplate={teaserAreasTemplate}
        colTemplate={teaserColTemplate}
        rowTemplate="1fr auto"
        colGap="2rem"
        miscStyles={{
          padding: [ { until: 's', value: '4rem', }, { from: 's', value: '2rem', }, ],
          overflow: 'visible',
          outline: isCommercial ? `1px solid ${theme.color('neutral', '-5')}` : undefined,
          fontFamily: isCommercial ? theme.fontStacks.commercial : undefined,
        }}
      >
        <TeaserHeader
          {...item}
          isGridItem
          hasBlockLink={false}
          onClick={onClick}
          kickerMiscStyles={{ color: theme.color('schoonhoven', 'kicker'), }}
          typeScale={[
            { until: 's', value: isCommercial ? 0 : -1, },
            { from: 's', value: 0, },
          ]}
          wrapperMiscStyles={{
            marginBottom: [
              { from: 'm', value: '-2rem', },
              { until: 's', value: '3rem', },
            ],
            marginEnd: [
              { from: 'xl', value: '26rem', },
              { from: 'l', until: 'xl', value: '30rem', },
              { from: 'm', until: 'l', value: '26rem', },
            ],
          }}
        />
        <TeaserMedia
          data={item}
          miscStyles={{ display: [ { until: 's', value: 'none', }, ], }}
          onClick={onClick}
        >
          <Image
            image={image}
            lazyLoad={isLazyloadImages}
            imgOptions={getImageAssets({
              bps: theme.bps,
              aspect: 'square',
              sizes: [
                { from: 'xl', size: '84px', },
                { from: 'm', size: '90px', },
                { size: '102px', },
              ],
              widths: [ 102, ],
            })}
          />
        </TeaserMedia>
        {isCommercial
          ? (
            <SchoonhovenLabelAudioSkin
              fileUrl={url}
              channelName={channelName || ''}
              contentName={item.title || title || ''}
            />
          ) : (
            <SchoonhovenAudioSkin
              fileUrl={url}
              channelName={channelName || ''}
              contentName={item.title || title || ''}
            />
          ) }
        {podcastChannelLinks && (
          <SchoonhovenRssLink podcastChannelLinks={podcastChannelLinks} theme={theme} isCommercial={isCommercial} />
        )}
      </Teaser>
      {displayRelatedArticles && relatedArticles && (
        <RelatedArticlesTeasers relatedArticles={relatedArticles} />
      )}
    </ListView>
  );
}

export default SchoonhovenView;
