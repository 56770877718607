// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

import AboveBlockLink from '../../../BlockLink/AboveBlockLink';
import HtzLink from '../../../HtzLink/HtzLink';
import IconBack from '../../../Icon/icons/IconBack';
import setBiAction from '../../../../utils/setBiAction';


type Props = {
  relatedArticles: TeaserDataType[],
  relatedPadding: string,
  biAction: ?ListBiActionType,
  numberOfRelatedArticles?: number,
  isDark?: boolean,
}

const relatedLinksWrapper = ({ theme, }) => ({
  gridArea: 'links',
  fontWeight: '700',
  fontFamily: theme.fontStacks[theme.framedFont],
  extend: [
    theme.mq({ until: 's', }, { display: 'none', }),
  ],
});

const baseLinkStyles = ({ theme, relatedPadding, isDark, }) => ({
  color: isDark ? theme.color('link', 'darkMainBlockRelatedArticles') : theme.color('link', 'mainBlockBase'),
  display: 'block',
  paddingInlineStart: relatedPadding,

  ':visited': {
    color: isDark ? theme.color('link', 'darkMainBlockRelatedArticles') : theme.color('link', 'mainBlockBase'),
  },
  ':hover': {
    color: isDark ? theme.color('link', 'darkMainBlockRelatedArticles') : theme.color('link', 'mainBlockBase'),
    textDecoration: 'underline',
  },
  ':focus': {
    color: isDark ? theme.color('link', 'darkMainBlockRelatedArticles') : theme.color('link', 'mainBlockBase'),
    textDecoration: 'underline',
    outline: 'none',
  },
  extend: theme.articleStyle.articleLink.baseLinkStylesExtend({ theme, }),
});

const lastLinkStyles = ({ theme, }) => ({
  extend: theme.mq(
    { until: 'xl', },
    { display: 'none', }),
});

RelatedArticleLinks.defaultProps = {
  numberOfRelatedArticles: 3,
  isDark: false,
};

export default function RelatedArticleLinks({
  relatedArticles,
  biAction,
  relatedPadding,
  numberOfRelatedArticles,
  isDark,
}: Props): React.Node {
  const { css, theme, } = useFela({ relatedPadding, isDark, });
  const wrapperClassName = css(relatedLinksWrapper);
  const baseLinkClassName = css(baseLinkStyles);
  const lastLinkClassName = css(lastLinkStyles);
  return (
    <ul className={wrapperClassName}>
      {relatedArticles.slice(0, numberOfRelatedArticles).map((article, i) => (
        <AboveBlockLink key={article.contentId}>
          {({ className: aboveBlockLinkClassName, }) => (
            <li className={aboveBlockLinkClassName}>
              <HtzLink
                href={article.path}
                className={`${baseLinkClassName} ${i === 2 ? lastLinkClassName : ''}`}
                onClick={setBiAction(i, article, biAction)}
              >
                <IconBack
                  size={[
                    { until: 'xl', value: 2, },
                    { from: 'xl', value: 1.5, },
                  ]}
                  miscStyles={{
                    marginInlineStart: `-${relatedPadding}`,
                    marginInlineEnd: '0.5rem',
                    transform: theme.direction === 'ltr' ? 'rotate(180deg)' : undefined,
                  }}
                />
                {article.title}
              </HtzLink>
            </li>
          )}
        </AboveBlockLink>
      ))}
    </ul>
  );
}
